import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Offices from "../components/offices"
const ContactPage = ({ data, path, pageContext }) => {
  const {
    meta_title, 
    meta_description, 
    about_title,
    offices_title,
    offices,
    staff_title,
    _meta
  } = data.prismic.allHomes.edges[0].node
  return(
    <Layout 
      offices={offices} 
      path={path} 
      staffTitle={staff_title} 
      officesTitle={offices_title} 
      title={meta_title}
      aboutTitle={about_title}
      darkBackground={true}
      lang={_meta.lang}
      pageLanguage={pageContext.siteLanguage}
      uid={'contact'}
    >
      <SEO title={meta_title} description={meta_description} />
      <section>
        <Offices officesTitle={offices_title} offices={offices} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPage($locale: String!) {
    prismic {
      allHomes(lang: $locale) {
        edges {
          node {
            meta_title
            meta_description
            about_title
            offices_title
            offices {
              postal_code
              eMail
              address
              telephone
              city
              country
            }
            _meta {
                  lang
                }
            staff_title
          }
        }
      }
    }
  }
`

export default ContactPage
