import React from "react"
import { Container, Row, Col } from "reactstrap"

const Offices = ({ officesTitle, offices }) => (
  <React.Fragment>
    <Container className="text-right pt-5">
      <div className="font-weight-bold font-bigger text-uppercase pt-3">
        {officesTitle ? officesTitle : `Contact`}
      </div>
      <Row className="pt-3">
        {offices.map((office, index) => 
          <Office key={index} office={office}/>
        )}
      </Row>
    </Container>
  </React.Fragment>
)

const Office = ({ office }) => (
  <Col
    className="text-right py-3"
    xs={{
      size: 12
    }}
    md={{
      size: 6
    }}
  >
    <a 
      href={`https://www.google.ee/maps/search/${office.address.replace(/\s/g, '+')},+${office.postal_code.replace(/\s/g, '+')}+${office.city.replace(/\s/g, '+')}+${office.country.replace(/\s/g, '+')}`}
      target="_blank"
      rel="noreferrer noopener"
      alt={`${office.city}: ${office.address}, ${office.postal_code}, ${office.country}`}
    >
      {office.address && 
        <div className="text-uppercase">
          {office.address}
        </div>
      }
      {(office.city || office.postal_code || office.country) && 
        <div className="text-uppercase">
          {`${office.city} ${office.postal_code}, ${office.country}`}
        </div>
      }
    </a> 
    {office.telephone && 
      <div>
        TEL: <a href={`tel:${office.telephone.replace(/\s/g, '')}`}>{office.telephone}</a>
      </div>
    }
    {office.eMail && 
      <div>
        E-mail: <a href={`mailto:${office.eMail}`}>{office.eMail}</a>
      </div>
    }
  </Col>
)

export default Offices